import { redirect, json as cloudflareJSON } from '@remix-run/cloudflare';
import { AuthenticationError, serializeHandleCookie } from '.';
import { setFlashMessage } from './flash';
import { getRedirectTo, getSession, isAdminLoggedIn, isLoggedIn, isVerified, setSessionCredentials, } from './sessions';
import { FLASH_STATUS } from './types';
export const commitSessionAndRedirect = async (args, credentials, authenticatable, appRoute, message) => {
    const { session, commitSession } = await getSession(args);
    setSessionCredentials(session, credentials, authenticatable);
    if (message) {
        setFlashMessage(session, message, FLASH_STATUS.NOTICE);
    }
    const headers = new Headers();
    headers.append('Set-Cookie', await commitSession(session));
    headers.append('Set-Cookie', serializeHandleCookie(args.context, authenticatable.creator.handle));
    return redirect(appRoute(args), { headers });
};
export const jsonResponse = async (body, { options = {}, args, flashes, } = {}) => {
    if (flashes && flashes.length > 0 && args) {
        const { session, commitSession } = await getSession(args);
        flashes.forEach((flash) => {
            setFlashMessage(session, flash.message, flash.type, flash.options);
        });
        return cloudflareJSON(body, {
            ...options,
            headers: {
                'Set-Cookie': await commitSession(session),
            },
        });
    }
    return cloudflareJSON(body, options);
};
export const commitNoticeAndRedirect = async (args, noticeMessage, appRoute) => {
    const { session, commitSession } = await getSession(args);
    setFlashMessage(session, noticeMessage, FLASH_STATUS.NOTICE);
    return redirect(appRoute(args), {
        headers: {
            'Set-Cookie': (await commitSession(session)) || '',
        },
    });
};
export const commitAlertAndRedirect = async (args, noticeMessage, appRoute) => {
    const { session, commitSession } = await getSession(args);
    setFlashMessage(session, noticeMessage, FLASH_STATUS.ALERT);
    return redirect(appRoute(args), {
        headers: {
            'Set-Cookie': (await commitSession(session)) || '',
        },
    });
};
export const checkUnAuthenticated = async (args, fallbackUrl, callback) => {
    const { session, commitSession } = await getSession(args);
    if (isLoggedIn(session)) {
        // Redirect back where they came from or to the creator admin
        return redirect(getRedirectTo(args, session, fallbackUrl), {
            headers: {
                'Set-Cookie': await commitSession(session),
            },
        });
    }
    if (callback) {
        return callback(session);
    }
    return null;
};
export const checkAuthenticated = async (args, loginRoute, callback) => {
    const { session, destroySession } = await getSession(args);
    if (!isLoggedIn(session)) {
        // Redirect to the home page if they are already signed in.
        return redirect(loginRoute(args));
    }
    if (!isVerified(session)) {
        return redirect(loginRoute(args), {
            headers: {
                'Set-Cookie': await destroySession(session),
            },
        });
    }
    if (!callback) {
        return null;
    }
    try {
        return await callback(session);
    }
    catch (error) {
        if (error instanceof AuthenticationError) {
            return redirect(loginRoute(args), {
                headers: {
                    'Set-Cookie': await destroySession(session),
                },
            });
        }
        throw error;
    }
};
export const checkAdminAuthenticated = async (args, loginRoute, callback) => {
    const { session, destroySession } = await getSession(args);
    if (!isAdminLoggedIn(session)) {
        // Redirect to the home page if they are already signed in.
        return redirect(loginRoute(args));
    }
    if (!callback) {
        return null;
    }
    try {
        return await callback(session);
    }
    catch (error) {
        if (error instanceof AuthenticationError) {
            return redirect(loginRoute(args), {
                headers: {
                    'Set-Cookie': await destroySession(session),
                },
            });
        }
        throw error;
    }
};
