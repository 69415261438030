export var FlashMessageStatus;
(function (FlashMessageStatus) {
    FlashMessageStatus["NOTICE"] = "notice";
    FlashMessageStatus["ALERT"] = "alert";
    FlashMessageStatus["INFO"] = "info";
})(FlashMessageStatus || (FlashMessageStatus = {}));
export var AuthRoles;
(function (AuthRoles) {
    AuthRoles["ADMIN"] = "ADMIN";
    AuthRoles["CREATOR"] = "CREATOR";
    AuthRoles["STUDENT"] = "STUDENT";
})(AuthRoles || (AuthRoles = {}));
export const FLASH_STATUS = FlashMessageStatus;
