import { z } from 'zod';
const EnvSchema = z.object({
    REMIX_ENV: z.enum(['development', 'preview', 'production']),
    ROOT_DOMAIN: z.string().min(1),
    ACCOUNTS_SUBDOMAIN: z.optional(z.string().min(1)),
    CREATOR_ADMIN_SUBDOMAIN: z.optional(z.string().min(1)),
    STOREFRONT_SUBDOMAIN: z.optional(z.string().min(1)),
    ONBOARDING_SUBDOMAIN: z.optional(z.string().min(1)),
    ADMIN_SUBDOMAIN: z.optional(z.string().min(1)),
});
const buildUrl = (subdomain, domain, remixEnv, noProtocol) => {
    if (remixEnv === 'development') {
        return `${noProtocol ? '' : 'http://'}${domain}${subdomain}`;
    }
    else if (subdomain === 'www') {
        return `${noProtocol ? '' : 'https://'}${domain}`;
    }
    else {
        return `${noProtocol ? '' : 'https://'}${subdomain}.${domain}`;
    }
};
const baseUrl = (subdomain, { context }, { noProtocol } = { noProtocol: false }) => {
    const env = EnvSchema.parse(context.env || context);
    const { REMIX_ENV, ROOT_DOMAIN } = env;
    return buildUrl(env[subdomain], ROOT_DOMAIN, REMIX_ENV, noProtocol);
};
export const accountsBaseUrl = (args, baseUrlArgs = { noProtocol: false }) => baseUrl('ACCOUNTS_SUBDOMAIN', args, baseUrlArgs);
export const creatorAdminBaseUrl = (args, baseUrlArgs = { noProtocol: false }) => baseUrl('CREATOR_ADMIN_SUBDOMAIN', args, baseUrlArgs);
export const storefrontBaseUrl = (args, baseUrlArgs = { noProtocol: false }) => baseUrl('STOREFRONT_SUBDOMAIN', args, baseUrlArgs);
export const onboardingBaseUrl = (args, baseUrlArgs = { noProtocol: false }) => baseUrl('ONBOARDING_SUBDOMAIN', args, baseUrlArgs);
export const AdminBaseUrl = (args, baseUrlArgs = { noProtocol: false }) => baseUrl('ADMIN_SUBDOMAIN', args, baseUrlArgs);
