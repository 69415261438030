import { GraphQLClient } from 'graphql-request';
import z from 'zod';
import { getCredentials, getSession } from './sessions';
const EnvSchema = z.object({
    GRAPH_URL: z.optional(z.string().min(1)),
});
const graphql_client = (context, headers = {}) => {
    const env = EnvSchema.parse(context.env || context);
    return new GraphQLClient(`${env.GRAPH_URL || 'http://localhost:4000/graphql'}`, {
        fetch,
        headers,
    });
};
export async function unauthenticated_request({ queryString, variables, args: { context }, }) {
    return await graphql_client(context).request(queryString, variables);
}
export class AuthenticationError extends Error {
    constructor(message) {
        super(message);
        this.name = 'AuthenticationError';
    }
}
export async function authenticated_request({ queryString, variables, args, }) {
    const { context } = args;
    const { session } = await getSession(args);
    try {
        const client = graphql_client(context, getCredentials(session));
        const response = await client.request(queryString, variables);
        return response;
    }
    catch (e) {
        if (e?.response?.errors?.[0]?.extensions?.code === 'AUTHENTICATION_ERROR') {
            throw new AuthenticationError('invalid session');
        }
        else {
            console.error(e);
        }
        throw e;
    }
}
export function dynamicRequest({ queryString, variables, args, }) {
    const url = new URL(args.request.url);
    const token = url.searchParams.get('token');
    const graphRequest = token ? unauthenticated_request : authenticated_request;
    if (token)
        variables.token = token;
    return graphRequest({ queryString, variables, args });
}
