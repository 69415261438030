import { createCookieSessionStorage } from '@remix-run/cloudflare';
import { requireEnv } from './env';
import { AuthRoles } from './types';
export async function getSession({ request, context }) {
    const cookieStorage = createCookieSessionStorage({
        cookie: {
            name: '__theleap_session_v2',
            domain: requireEnv(context, 'ROOT_DOMAIN'),
            httpOnly: true,
            maxAge: 60 * 60 * 24 * 30, // 30 days
            path: '/',
            sameSite: 'lax',
            secrets: [requireEnv(context, 'COOKIE_SECRET')],
            secure: requireEnv(context, 'ROOT_DOMAIN') !== 'localhost',
        },
    });
    return {
        session: await cookieStorage.getSession(request.headers.get('Cookie')),
        commitSession: cookieStorage.commitSession,
        destroySession: cookieStorage.destroySession,
    };
}
export function getCredentials(session) {
    return {
        uid: session.get('uid'),
        'access-token': session.get('access-token'),
        client: session.get('client'),
    };
}
export function isLoggedIn(session) {
    return (session.has('uid') &&
        session.has('access-token') &&
        session.has('client') &&
        session.has('role'));
}
export function isVerified(session) {
    return (session.has('uid') &&
        session.has('access-token') &&
        session.has('client') &&
        session.has('role') &&
        session.has('otpExpiresAt') &&
        session.get('otpExpiresAt') > new Date().toISOString());
}
export function isAdminLoggedIn(session) {
    return (session.has('uid') &&
        session.has('access-token') &&
        session.has('client') &&
        session.has('role') &&
        session.get('role') === AuthRoles.ADMIN);
}
export function storeCreatorLoggedIn(session, handle) {
    return session.get('handle') === handle && session.get('role') === 'CREATOR';
}
export function getRedirectTo(args, session, defaultAppRoute) {
    const redirectTo = session.get('redirectTo');
    if (redirectTo) {
        session.unset('redirectTo');
    }
    return redirectTo || defaultAppRoute(args);
}
export function saveRedirectTo(redirectTo, session) {
    session.flash('redirectTo', redirectTo);
}
export const setSessionCredentials = (session, credentials, authenticatable) => {
    session.set('uid', credentials.uid);
    session.set('access-token', credentials.accessToken);
    session.set('client', credentials.client);
    session.set('role', authenticatable.role);
    session.set('handle', authenticatable.creator?.handle);
    session.set('otpExpiresAt', authenticatable.otpExpiresAt);
};
export const getToastMessage = (session) => session.get('flashMessage');
