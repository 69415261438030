import { redirect } from '@remix-run/cloudflare';
import { IdentityProviders } from '.';
import { oauthCookie } from './cookies';
export function oAuthUrl(env, provider, state, redirectUri) {
    if (provider === IdentityProviders.enum.INSTAGRAM) {
        return `https://api.instagram.com/oauth/authorize?client_id=${env.INSTAGRAM_CLIENT_ID}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=user_profile,user_media&response_type=code&state=${state}`;
    }
    else {
        return `https://www.tiktok.com/v2/auth/authorize/?client_key=${env.TIKTOK_CLIENT_ID}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=user.info.basic&response_type=code&state=${state}`;
        // ,user.info.profile,user.info.stats,video.list
    }
}
export async function handleOAuthRedirect(args, provider, redirectUri, appState) {
    const { context } = args;
    const state = crypto.randomUUID();
    const oauthUrl = oAuthUrl(context.env, IdentityProviders.parse(provider.toUpperCase()), state, redirectUri);
    return redirect(oauthUrl, {
        headers: {
            'Set-Cookie': await oauthCookie(context).serialize({
                state,
                ...appState,
            }),
        },
    });
}
