import { createCookie } from '@remix-run/cloudflare';
import { requireEnv } from '.';
export function flagsCookie(context) {
    return createCookie('leap-flags', {
        maxAge: 604_800, // one week
        path: '/',
        sameSite: 'lax',
        domain: requireEnv(context, 'ROOT_DOMAIN'),
    });
}
export async function readFlagsCookie({ request, context, }) {
    const cookieHeader = request.headers.get('Cookie');
    return (await flagsCookie(context).parse(cookieHeader)) || {};
}
export async function readOrSetFlagsCookie(args, flag) {
    const flags = await readFlagsCookie(args);
    let newValue = false;
    if (flag) {
        const url = new URL(args.request.url);
        const newFlagValue = url.searchParams.get(flag);
        if (newFlagValue) {
            // ignore if flag param is not set
            flags[flag] = newFlagValue === 'true';
            newValue = true;
        }
    }
    return [flags, newValue];
}
export async function serializeFlagsCookie(args, flags) {
    return await flagsCookie(args.context).serialize(flags);
}
