import { createCookie } from '@remix-run/cloudflare';
import { requireEnv } from '.';
export function onboardingCookie(context) {
    return createCookie('leap-onboarding', {
        maxAge: 604_800, // one week
        path: '/',
        sameSite: 'lax',
        domain: requireEnv(context, 'ROOT_DOMAIN'),
    });
}
export async function readOnboardingCookie({ request, context, }) {
    const cookieHeader = request.headers.get('Cookie');
    return (await onboardingCookie(context).parse(cookieHeader)) || {};
}
// This cookie is being used in Cloudflare proxy worker to bypass cach
// when the creator is logged in and checking its own pages
export function serializeHandleCookie(context, handle) {
    return `__theleap_handle=${handle}; Max-Age=604800; Domain=${requireEnv(context, 'ROOT_DOMAIN')}; Path=/; HttpOnly; SameSite=Lax;`;
}
export function oauthCookie(context) {
    return createCookie('__theleap_oauth', {
        maxAge: 300, // 5 minutes
        httpOnly: true, // IMPORTANT
        path: '/',
        sameSite: 'lax',
        secrets: [requireEnv(context, 'COOKIE_SECRET')],
        domain: requireEnv(context, 'ROOT_DOMAIN'),
        secure: requireEnv(context, 'ROOT_DOMAIN') !== 'localhost',
    });
}
export async function readOauthCookie({ request, context }) {
    const cookieHeader = request.headers.get('Cookie');
    return (await oauthCookie(context).parse(cookieHeader)) || {};
}
